import { createApp } from 'vue'
import App from './App.vue'
import Vue from 'vue'
import axios from 'axios'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'leaflet/dist/leaflet.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import '@/assets/MarkerCluster.Default.css'
import '@/assets/leaflet.markercluster-src.js'


console.log(process.env.VUE_APP_API_URL)
axios.defaults.baseURL = process.env.VUE_APP_API_URL
const app = createApp(App)
app.config.globalProperties.$http = axios
app.mount('#app')

// Vue.filter('toCurrency', function (value) {
//     if (typeof value !== "number") {
//         return value;
//     }
//     var formatter = new Intl.NumberFormat('en', {
//         style: 'currency',
//         currency: 'GBP',
//         minimumFractionDigits: 0
//     });
//     return formatter.format(value);
// });
