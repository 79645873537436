<template>
<div class="fullheight">
  <div class="h-100 sidebar">
    <div class="haaauz-title">
      HAAAUZ
    </div>
    <div class="postcode-input-container">
      <input class="postcode-input" placeholder="POSTCODE.." type="text" v-model="postcode" />
      <div @click="getPostcode" class="postcode-input-btn">
        <i class="bi bi-search text-white"></i>
      </div>
    </div>
    <div class="show-all" title="Show All">
      <title>Show All</title>
      <i @click="showAll = true" v-show="!showAll" class="bi bi-eye-fill"></i>
      <i @click="showAll = false" v-show="showAll" class="bi bi-eye-slash-fill"></i>
    </div>

    <div v-if="firstLoad" class="first-load-layer">
      <div class="double-border">

      </div>
      <!-- <img src="@/assets/blacksun.png" class="spinny-thing" style="height:70px;"> -->
    </div>
    <div class="sidebar-results">

      <div class="pb-5 bg-white">
        <!-- {{currentMarkerId}} -->
        <div v-show="result.marker_id === currentMarkerId" class="overflow-auto bg-white" v-for="result in results">
          <div class="pointer" v-for="(item, key) in result.grouped_records">
            <div @click="setBuilding(key)" :class="{'building-selected': key === currentBuilding}" class="w-100 ps-1 pe-1 building-item">
              {{key}}
              <span class="float-end">+{{item.length}}</span>
            </div>

            <div v-show="currentBuilding === key || showAll" class="">
              <div v-for="record in item">
                <div class="w-100 flat-item ps-1 pe-1">
                  <span class="ps-1 flat-number">
                    {{record.flat_number || '-'}}
                  </span>
                  <span class="float-end ms-2">
                    {{record.date}}
                  </span>
                  <span class="float-end ms-2 ">
                    <b>
                      £{{record.formatted_price}}
                    </b>
                  </span>
                  <span class="float-end ms-2 ">
                    <!-- <b> -->
                    <span v-if="record.price_diff" :class="{'text-danger': record.price_diff< 0, 'text-success': record.price_diff > 0}">
                      {{toPrice(record.price_diff)}}
                    </span>
                    <!-- </b> -->
                  </span>
                </div>
                <!-- {{record}} -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="debug" class="clear-redis-btn">
    <div @click="clearAPIcache" class="btn btn-info">
      clear redis
    </div>
  </div>

  <div class="fullscreen" id="map-canvas"> </div>

  <div v-if="loading" class="fullscreen" id="map-loading-overlay"> </div>




</div>
</template>

<script>
import { latLng } from "leaflet"
import L from "leaflet"

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


export default {
  name: 'Home',
  data() {
    let postcode = null
    const debug = process.env.VUE_APP_DEBUG === 'true'
    if (debug) {
      postcode = 'SE155EB'
      postcode = 'SE155BS'
    }
    return {
      debug: debug,
      firstLoad: true,
      map: {},
      error: '',
      errorTimeout: null,
      loading: false,
      postcode: postcode,
      long: 0,
      lat: 0,
      results: {},
      current: null,
      showSidebar: true,
      defaultZoom: 18,
      maxZoom: 19,
      center: [51, -0.07],
      mapMovedTimeout: null,
      itemRefs: {},
      currentMarkerId: '',
      currentBuilding: [],
      loadingMarkers: false,
      showAll: false,
    }
  },
  mounted: function() {
    this.setupDefaultMap()
    if (process.env.VUE_APP_DEBUG === 'true') {
      this.firstLoad = false
      this.getPostcode()

    }
  },
  methods: {
    setItemRef(el) {
      if (el) {
        this.itemRefs[el.id] = el
      }
    },
    setErrorMessage(msg) {
      this.error = msg
      clearTimeout(this.errorTimeout)
      this.errorTimeout = setTimeout(() => {
        this.error = ''
      }, 2000)
    },
    toPrice(price) {
      // minimumFractionDigits: 2
      let toadd = price > 0 ? '+' : ''
      let diff = price.toLocaleString("en-GB", { minimumFractionDigits: 0 })
      return toadd + diff
    },
    setMarkerId(el) {
      let target = el.originalEvent.target
      // console.log(id)
      console.log(target.postcode)
      this.postcode = target.value
      this.currentMarkerId = target.id
    },
    setBuilding(key) {
      this.currentBuilding = this.currentBuilding === key ? '' : key
    },
    getPostcode: function() {
      this.firstLoad = false
      this.loading = true
      this.$http.get(`find/postcode/location/${this.postcode}`).then((response) => {
        this.loading = false
        if (response.data.error) {
          console.log(response.data.error)
          this.setErrorMessage(response.data.error)
        } else {
          this.postcode = response.data.postcode
          this.long = response.data.longitude
          this.lat = response.data.latitude
          this.updateMap()
          this.getResults()
        }
      })
    },
    getResults: function() {
      this.loadingMarkers = true
      this.$http.get(`find/records/${this.postcode}/0.5`).then((response) => {
        this.loadingMarkers = false
        if (response.data.error) {
          this.setErrorMessage(response.data.error)
        } else {
          this.results = response.data.results
          this.setMarkers()
        }
      })
    },
    clearAPIcache: function() {
      this.$http.get(`reset_cache`).then((response) => {})
    },
    moved: function(x) {
      clearTimeout(this.mapMovedTimeout)
      this.mapMovedTimeout = setTimeout(function() { console.log('map moved') }, 1000);
      // var center = this.map.getCenter()
    },
    updateMap() {
      this.map.setView(latLng(this.lat, this.long), this.defaultZoom)
      // var center = this.map.getCenter()
    },
    setMarkers: function() {
      const markers = L.markerClusterGroup()

      function emitstuf() {
        this.$emit('hello')
      }

      function markerIcon(item) {
        let markerhtml = `
          <button id="${item.marker_id}" value="${item.postcode}"  type="button" class="btn btn-light position-absolute btn-sm postcode-marker">
            ${item.postcode}
            <span class="postcode-marker-tag position-absolute top-0 start-100 translate-middle badge rounded-pill">
              ${item.address_count}
            </span>
          </button>
        `
        // console.log(markerhtml)
        return markerhtml
      }


      for (let index in this.results) {
        let item = this.results[index]
        let pos = item.position
        let marker = L.marker([pos.latitude, pos.longitude], {
          icon: L.divIcon({
            html: markerIcon(item)

          })
        })
        marker.on('click', this.setMarkerId)
        // marker.bindPopup(() => {
        //   return this.itemRefs[item.popup_id]
        // marker.openPopup()
        markers.addLayer(marker)
      }
      this.map.addLayer(markers)

    },
    setupDefaultMap: function() {
      const map = L.map('map-canvas', {
        zoom: this.defaultZoom,
        zoomDelta: 0.5,
        zoomSnap: 0.5,
        fullscreenControl: true,
        center: this.center,
        // zoomAnimation: false
      })
      let mapboxtoken = 'pk.eyJ1IjoiaGFhYXVzIiwiYSI6ImNrbW0xdDYxYjBibjAyb216aHhqbG1xYW4ifQ.MQ_10FKXgfsI4TSWW8wQWg'

      const tile_url_default = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      const tile_url_mapbox_light = 'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=' + mapboxtoken
      const baseLayerOptions = {
        // attribution: '...',
        maxZoom: this.maxZoom
      }
      L.tileLayer(tile_url_mapbox_light, baseLayerOptions).addTo(map)
      // L.tileLayer(tile_url_default, baseLayerOptions).addTo(map)
      this.map = map
      // this.map.on('moveend', this.moved)
    },
    // setPopUpZIndex: function() {
    //   let paneCol = document.getElementsByClassName('leaflet-popup-pane')
    //   let paneToChange = paneCol[0];
    //   console.log(paneToChange);
    //   paneToChange.style.zIndex = '9999'
    // },
  },
}
</script>

<style >
:root {
  --logo-1: #3fc6d5;
  --logo-2: #e10bca91;
}

.sidebar {
  width: 370px;
  z-index: 8888;
  background: white;
  position: absolute;
}

.pointer {
  cursor: pointer
}

.haaauz-title {
  font-size: 90px;
  position: fixed;
  top: -49px;
  font-weight: bold;
  padding: 0px;
  left: -8px;
  margin: 0px;
  font-family: helvetica;
}

/* input = 185 middle, */
/* width/2 - middle = left: */
.postcode-input-container {
  z-index: 999;
  position: fixed;
  top: 50px;
  left: 92px;
  background: gray;
  border: 3px solid black;
}

.postcode-input {
  width: 150px;
  font-family: helvetica;
  text-transform: uppercase;
  text-align: center;
  border: none;
}

.postcode-input-btn {
  width: 30px;
  background: grey;
  height: 100%;
  display: inline-block;
  cursor: pointer;
}

.first-load-layer {
  z-index: 998;
  width: 100%;
  height: 100%;
  background: grey;
  position: fixed;
  opacity: 0.6;
}


.sidebar-results {
  /* position: absolute; */
  margin-top: 100px;
  text-align: left;
}

.flat-item {
  background: #d9d9d9;
  background: #fff3de;
}

.building-item:hover {
  background: #fbefe5;
}

.flat-number {
  /* display: block ruby; */
  width: 111px;
  overflow: auto;
}

.fullscreen {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  /* background: rgba(51,51,51,0.7); */
  z-index: 10;
}

.clear-redis-btn {
  z-index: 999;
  position: absolute;
  right: 0;
}

#map-loading-overlay {
  background: grey;
  opacity: 0.2;
}

.building-selected {
  background: #fff3de;
}

.spinny-thing {
  animation: rotate 35s linear infinite;
  padding: 2px;
  text-align: justify;
}

.show-all {
  position: fixed;
  top: 46px;
  left: 299px;
  font-size: 28px;
  color: grey;
  cursor: pointer;
}

.postcode-marker {
  background: rgb(44, 62, 80) none repeat scroll 0% 0% !important;
  color: white !important;
  font-family: monospace;
  border: none !important;
  margin-left: -39px;
  margin-top: -10px;
}


.postcode-marker-tag {
  background-color: #678ea2 !important;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
